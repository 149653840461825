import React from "react";

import Projects from "./Projects";

const Work = () => {
    return (
        <section id="work" className="section bg-primary min-h-[1000px]">
            <div className="container mx-auto">
                <div className="flex flex-col items-center text-center">
                    <h2 className="section-title before:content-work relative before:absolute before:opacity-10 before:-top-[4.5rem] before:-left-44 before:hidden before:lg:block ">
                        My work
                    </h2>
                    <p className="subtitle">
                        My work is divided roughly into three tear categories:
                        Basic, Standard, and Premium. These tears can be
                        addapted if necessary <br />
                        <span className="text-accent">
                            {" "}
                            Each of these categories includes setting up domain
                            name and hosting for your website, or walkthrough
                            consultations if needed.
                        </span>
                    </p>
                </div>
                <Projects />
            </div>
        </section>
    );
};

export default Work;
