import React from "react";

const Project = ({ item }) => {
    const [...servicesArr] = item.services;
    return (
        <div
            key={item.id}
            className="flex flex-col items-center justify-center"
        >
            <div className="mb-8 relative flex flex-col items-center justify-center ">
                <img
                    className="rounded-2xl w-[500px] h-[300px]"
                    src={item.image}
                    alt=""
                />
                <button
                    type="button"
                    className="absolute text-white   bg-accent text-xl px-4 py-2 rounded-lg hover:scale-105 hover:bg-accent-hover transition-all duration-300 ease-in-out"
                >
                    <a href={item.url}>See an Example</a>
                </button>
            </div>
            <h3 className="text-3xl font-semibold capitalize mb-3">
                {item.name}
            </h3>
            <p className="capitalize text-gray-400 text-md mb-3">
                {item.category}
            </p>
            <ul className="w-[100%]">
                <li key={item.id} className="capitalize block">
                    {servicesArr.map((service, index) => {
                        return (
                            <>
                                {service}
                                <br />
                            </>
                        );
                    })}
                </li>
            </ul>
        </div>
    );
};

export default Project;
