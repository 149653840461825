import React from "react";
import { Link } from "react-scroll";
import Profilna from "../assets/img/profilna.webp";

const Hero = () => {
    return (
        <section
            id="home"
            className="lg:h-[100vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden"
        >
            <div className="container mx-auto h-full">
                <div className="flex items-center h-full pt-8">
                    <div className="flex-1 flex flex-col items-center lg:items-start">
                        <p className="text-lg text-accent mb-[22px]">
                            Hello, I'm Mario{" "}
                            <div className="inline-block hover:animate-ping cursor-pointer">
                                👋
                            </div>
                        </p>
                        <h1 className="text-center text-3xl leading-[44px] md:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px]">
                            I wright code &<br />
                            make Web Sites.
                        </h1>
                        <p className="pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-lg text-center lg:text-left">
                            Attract more visitors, and convert them into
                            customers, with an attractive website that combines
                            speed with design. <br />
                            <span className="animate-bounce mt-2 cursor-pointer hover:animate-none inline-block">
                                👇
                            </span>
                        </p>
                        <button className="btn btn-md text-black font-bold rounded-lg bg-accent hover:bg-accent-hover md:btn-lg transition-all">
                            <Link
                                to="services"
                                smooth={true}
                                duration={500}
                                offset={-70}
                                className="transition-all duration-300 cursor-pointer "
                            >
                                See how
                            </Link>
                        </button>
                    </div>
                    <div className="hidden lg:flex flex-1 justify-end items-end h-full">
                        <img
                            className="w-[20vw] h-auto mb-64
                            border-none rounded-3xl"
                            src={Profilna}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
