import React from "react";

import { contact } from "../data";

const Contact = () => {
    return (
        <section id="contact" className="section bg-primary">
            <div className="container mx-auto">
                <div className="flex flex-col items-center text-center">
                    <h2 className="section-title before:content-contact relative before:absolute before:opacity-10 before:-top-16 before:-left-40 before:hidden before:lg:block">
                        Contact me
                    </h2>
                    <p className="mb-4 text-accent">Let's Get in Touch</p>
                    <p className="subtitle">
                        Got a project in mind or a question about my services?
                        I&apos;m here to help. Send me a message or an email,
                        and I&apos;ll get back to you quickly. Let&apos;s start
                        your next project together.
                    </p>
                </div>
                <div className="flex flex-col lg:gap-x-8 lg:flex-row">
                    <div className="flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2">
                        {contact.map((item, index) => {
                            const { icon, title, subtitle, description } = item;
                            return (
                                <div
                                    className="flex flex-col lg:flex-row gap-x-4"
                                    key={index}
                                >
                                    <div className="text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl">
                                        {icon}
                                    </div>
                                    <div className="">
                                        <h4 className="font-body text-xl mb-1 ">
                                            {title}
                                        </h4>
                                        <p className="mb-1">{subtitle}</p>
                                        <br />
                                        <p className="mb-1 ">
                                            Use the form, or email me directly
                                            at
                                        </p>

                                        <p className="text-greenAccent hover:text-accent font-normal cursor-pointer transition-all duration-300 ease-in-out">
                                            <a href="mailto:mario@mariopolchert.com">
                                                {description}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <form
                        action="https://getform.io/f/c0bce153-cf37-4ca4-a60d-da6ce134687b"
                        method="POST"
                        className="flex flex-col space-y-8 w-full max-w-[780px]"
                    >
                        <div className="flex flex-col lg:flex-row justify-between  ">
                            <input
                                className="input mr-4 rounded-lg"
                                type="text"
                                name="name"
                                placeholder="Your name"
                            />
                            <input
                                className="input mt-8 lg:mt-0 rounded-lg"
                                type="email"
                                name="email"
                                placeholder="Your email"
                            />
                        </div>
                        <input
                            type="text"
                            name="subject"
                            className="input rounded-lg"
                            placeholder="Subject"
                        />
                        <textarea
                            type="text"
                            name="message"
                            className="textarea rounded-lg"
                            placeholder="Your message"
                        ></textarea>
                        {/* add hidden Honeypot input to prevent spams */}
                        <input
                            type="hidden"
                            name="_gotcha"
                            className="display:none !important"
                        ></input>
                        <button
                            className="btn  text-black font-bold rounded-lg bg-accent lg:w-[12rem] hover:bg-accent-hover"
                            type="submit"
                            name="submit"
                        >
                            Send message
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Contact;
