import React from "react";
import { services } from "../data";

const Services = () => {
    return (
        <section id="services" className="section bg-tertiary">
            <div className="container mx-auto">
                <div className="flex flex-col items-center text-center">
                    <h2 className="section-title before:content-services relative before:absolute before:opacity-5 before:-top-[5rem] before:-left-20 before:hidden before:lg:block ">
                        What I do for clients
                    </h2>
                    <p className="subtitle ">
                        I create websites that are not only good-looking and
                        easy to use but also effective. I&apos;ll guide you from
                        the initial idea all the way to the final launch,
                        ensuring a smooth and successful digital experience for
                        your brand.
                    </p>
                </div>
                <div className="grid lg:grid-cols-4 gap-8">
                    {services.map((service, index) => {
                        const { icon, name, description } = service;
                        return (
                            <div
                                className="bg-secondary p-6 rounded-2xl"
                                key={index}
                            >
                                <div className="text-accent rounded-sm w-12 h-12 flex justify-center items-center mb-24 text-[28px]">
                                    {icon}
                                </div>
                                <h4 className="text-xl font-medium mb-2">
                                    {name}
                                </h4>
                                <p>{description}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Services;
